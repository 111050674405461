/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = ({ title, description }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }} paddingBottom={3}>
              {title} {/* Pull title from props */}
            </Typography>
            <Typography
              component={'p'}
              sx={{
                letterSpacing: '0.5px',  // Adds spacing between words
                lineHeight: '1.7',        // Adds line height for better readability
              }}
            >
              {description} {/* Pull description from props */}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box 
            maxWidth={500} 
            width={1} 
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component={'img'}
              src={
                'https://images.pexels.com/photos/1906658/pexels-photo-1906658.jpeg'
              }
              alt='cosmic space exploration illustration'
              width={1}
              height="auto"
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                borderRadius: '16px',
                boxShadow: '0 0 20px rgba(0, 255, 255, 0.4)', // Cyan glow effect matching the space theme
                transition: 'all 0.3s ease-in-out',
                objectFit: 'cover',
                maxHeight: '100%',
                '&:hover': {
                  boxShadow: '0 0 30px rgba(0, 255, 255, 0.7)', // Enhanced glow on hover
                  
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
