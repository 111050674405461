import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';

// Define the pages array directly within this file
const pages = [
  {
    groupTitle: 'Nebula Networks', // Group title for the navigation items
    pages: [
      {
        title: 'Home',
        href: '/', // Link to the Home page
      },
      {
        title: 'Services',
        href: '/services', // Link to the Services page
      },
      {
        title: 'About',
        href: '/about', // Link to the About page
      },
    ],
  },
];

const Sidebar = ({ open, variant, onClose }) => {
  return (
    <Drawer
      anchor="right"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 256,
          top: { xs: 0, md: 71 },
          height: { xs: '100%', md: 'calc(100% - 71px)' },
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 1,
          paddingTop: { xs: 2, sm: 3 },
        }}
      >
        {/* Pass the local pages array to the SidebarNav */}
        <SidebarNav pages={pages} onClose={onClose} />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
