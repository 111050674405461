import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from 'components/Container';

const HeroImageRight = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box bgcolor={'alternate.main'} py={6} sx={{ marginTop: '80px' }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item container xs={12} md={6} alignItems={'center'}>
            <Box>
              <Box marginBottom={5}>
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{ 
                    fontWeight: 700,
                    mb: 2,
                    lineHeight: 1.3
                  }}
                >
                  Strengthen your IT Infrastructure
                  <br />
                  <Typography
                    color={'primary'}
                    component={'span'}
                    variant={'inherit'}
                    sx={{ ml: 1 }}
                  >
                    & Security
                  </Typography>
                </Typography>
              </Box>
              <Box marginBottom={5}>
                <Typography 
                  variant="h6" 
                  component="p" 
                  color="text.secondary"
                  sx={{ lineHeight: 1.6 }}
                >
                  Bringing best practices in Managed IT and Cybersecurity
                  Solutions to your organization.
                  <br />
                  Start building better defenses today.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                mt={3}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isMd ? false : true}
                  sx={{ 
                    height: 54,
                    whiteSpace: 'nowrap',
                    minWidth: 180,
                    fontSize: '1.1rem',
                    py: 2, 
                    px: 4,
                    background: 'linear-gradient(90deg, #1E88E5 0%, #00BFA5 100%)',
                    '&:hover': {
                      boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
                      background: 'linear-gradient(90deg, #1976D2 0%, #00A896 100%)',
                    }
                  }}
                  onClick={() => {
                    const element = document.getElementById('contact-form');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems={'center'}
            justifyContent={'center'}
            xs={12}
            md={6}
          >
            <Box
              component={'img'}
              height={1}
              width={1}
              src={
                'https://images.unsplash.com/photo-1667372283496-893f0b1e7c16?q=80&w=1332&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              }
              alt="..."
              borderRadius={2}
              maxWidth={600}
              maxHeight={500}
              sx={{
                objectFit: 'cover',
                boxShadow: '19px 20px 0px 0 rgb(140 152 164 / 13%)',
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroImageRight;
