/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Validation schema
const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      'Please enter a valid phone number.',
    ),
  message: yup
    .string()
    .trim()
    .max(500, 'The message cannot contain more than 500 characters'),
});

const Form = ({ showHeadline = true, title = 'Let us help you secure your business', subtitle = 'Contact Us' }) => {
  // State for notification
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };

  const onSubmit = async (values, actions) => {
    try {
      setNotification({
        open: true,
        message: 'Sending your message...',
        severity: 'info'
      });
      
      // In development, use a mock API response
      if (process.env.NODE_ENV === 'development') {
        setTimeout(() => {
          // Success notification with space-themed styling
          setNotification({
            open: true,
            message: 'Thank you! Your message has been sent successfully.',
            severity: 'success'
          });
          
          // Reset the form
          actions.resetForm();
          actions.setSubmitting(false);
        }, 1500);
        return;
      }
      
      // In production, use the real API
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (response.ok) {
        // Success
        setNotification({
          open: true,
          message: 'Thank you! Your message has been sent successfully.',
          severity: 'success'
        });
        actions.resetForm();
      } else {
        // API error
        setNotification({
          open: true,
          message: data.message || 'Something went wrong. Please try again later.',
          severity: 'error'
        });
      }
      
      actions.setSubmitting(false);
    } catch (error) {
      // Network or other error
      setNotification({
        open: true,
        message: 'Unable to send your message. Please try again later.',
        severity: 'error'
      });
      actions.setSubmitting(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box id="contact-form">
      {showHeadline && (
        <Box 
          paddingTop={'40px'} 
          paddingBottom={'20px'} 
          paddingLeft={'15%'} 
          paddingRight={'15%'}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              marginBottom: 2,
            }}
            gutterBottom
            color={'secondary'}
            align={'center'}
            className="neon-text"
          >
            {subtitle}
          </Typography>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              align={'center'}
              sx={{
                fontWeight: 700,
                lineHeight: 1.3,
              }}
              className="neon-text-magenta"
            >
              {title}
            </Typography>
          </Box>
        </Box>
      )}
      
      <Box 
        paddingTop={'20px'} 
        paddingBottom={'80px'}
        paddingLeft={'15%'} 
        paddingRight={'15%'}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box component={Grid} marginBottom={{ xs: 10, sm: 0 }} container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Please enter your first name *
              </Typography>
              <TextField
                id="firstName"
                aria-describedby="firstNameError"
                label="First name"
                variant="outlined"
                name={'firstName'}
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                autoComplete="given-name"
                sx={{ mb: 3 }}
              />
              <Typography id="firstNameError" role="alert" aria-live="polite">
                {formik.touched.firstName && formik.errors.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Please enter your last name *
              </Typography>
              <TextField
                id="lastName"
                aria-describedby="lastNameError"
                label="Last name"
                variant="outlined"
                name={'lastName'}
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                autoComplete="family-name"
                sx={{ mb: 3 }}
              />
              <Typography id="lastNameError" role="alert" aria-live="polite">
                {formik.touched.lastName && formik.errors.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Please enter your email address *
              </Typography>
              <TextField
                id="email"
                aria-describedby="emailError"
                label="Email"
                variant="outlined"
                name={'email'}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="email"
                sx={{ mb: 3 }}
              />
              <Typography id="emailError" role="alert" aria-live="polite">
                {formik.touched.email && formik.errors.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Please enter your phone number (optional)
              </Typography>
              <TextField
                id="phone"
                aria-describedby="phoneError"
                label="Phone number"
                variant="outlined"
                name={'phone'}
                fullWidth
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                autoComplete="tel"
                sx={{ mb: 3 }}
              />
              <Typography id="phoneError" role="alert" aria-live="polite">
                {formik.touched.phone && formik.errors.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                Please tell us about your concerns (optional)
              </Typography>
              <TextField
                id="message"
                aria-describedby="messageError"
                label="Message"
                variant="outlined"
                name={'message'}
                fullWidth
                multiline
                rows={6}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                sx={{ mb: 4 }}
              />
              <Typography id="messageError" role="alert" aria-live="polite">
                {formik.touched.message && formik.errors.message}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                margin={'0 auto'}
                mt={4}
              >
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth={true}
                  sx={{ 
                    mt: 3,
                    background: 'linear-gradient(90deg, #1E88E5 0%, #00BFA5 100%)',
                    '&:hover': {
                      boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
                      background: 'linear-gradient(90deg, #1976D2 0%, #00A896 100%)',
                    }
                  }}
                >
                  {formik.isSubmitting ? 'Sending...' : 'Send'}
                </Button>
              </Box>
            </Grid>
          </Box>
        </form>
      </Box>

      {/* Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ 
            width: '100%',
            boxShadow: notification.severity === 'success' 
              ? '0 0 10px rgba(0, 255, 255, 0.7)' 
              : '0 0 10px rgba(255, 0, 0, 0.7)',
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Form;
