import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha, colors } from '@mui/material';
import Container from 'components/Container';
import { Link as RouterLink } from 'react-router-dom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BugReportIcon from '@mui/icons-material/BugReport';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import GavelIcon from '@mui/icons-material/Gavel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LoopIcon from '@mui/icons-material/Loop';
import SchoolIcon from '@mui/icons-material/School';

const iconMapping = {
  VerifiedUserIcon: <VerifiedUserIcon />,
  BugReportIcon: <BugReportIcon />,
  SecurityIcon: <SecurityIcon />,
  CloudIcon: <CloudIcon />,
  GavelIcon: <GavelIcon />,
  AssessmentIcon: <AssessmentIcon />,
  LoopIcon: <LoopIcon />,
  SchoolIcon: <SchoolIcon />,
};

const FeatureCards = ({ services, heading, subtitle }) => {
  return (
    <Box bgcolor={'alternate.main'} py={6}>
      <Container>
        <Box marginBottom={6}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              mb: 2,
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
          >
            {heading}
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
              mb: 3,
            }}
          >
            {subtitle}
          </Typography>
        </Box>

        <Grid container spacing={6}>
          {services.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  width={1}
                  height={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                  }}
                >
                  <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={3}
                      bgcolor={alpha(colors.indigo[500], 0.1)}
                      color={colors.indigo[500]}
                    >
                      {iconMapping[item.icon]}
                    </Box>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 600, mb: 2 }}
                      className="neon-text"
                    >
                      {item.title}
                    </Typography>
                    <Typography sx={{ mb: 3 }}>{item.subtitle || item.description || item.content}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-start'} mt={2}>
                    <Button
                      component={RouterLink}
                      to={item.link || '#'}
                      endIcon={
                        <Box
                          component={'svg'}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </Box>
                      }
                      size="large"
                      sx={{ mt: 2 }}
                      onClick={(e) => {
                        if (item.link && item.link.includes('#')) {
                          // Debug logging
                          console.log('Learn More clicked for:', item.title);
                          console.log('Link:', item.link);
                          
                          // Track the button click for analytics
                          if (typeof window.gtag === 'function') {
                            window.gtag('event', 'click', {
                              event_category: 'button',
                              event_label: `Learn More - ${item.title}`,
                            });
                          }
                          
                          // If we're already on the services page, handle the scroll manually
                          if (window.location.pathname === '/service' || window.location.pathname === '/service/') {
                            e.preventDefault();
                            console.log('Already on service page, handling scroll manually');
                            
                            const targetId = item.link.split('#')[1];
                            console.log('Target ID:', targetId);
                            
                            // First try with the detail- prefix (for the detailed view)
                            const detailTargetId = 'detail-' + targetId;
                            console.log('Looking for detailed view with ID:', detailTargetId);
                            
                            // Log all IDs on the page for debugging
                            const allElements = document.querySelectorAll('[id]');
                            console.log('Available IDs on page:');
                            allElements.forEach(el => console.log(`- ${el.id} (${el.tagName})`));
                            
                            // Try to find the element with the detail prefix first
                            let targetElement = document.getElementById(detailTargetId);
                            
                            // If not found, try the original ID
                            if (!targetElement) {
                              console.log('Detailed view not found, trying original ID:', targetId);
                              targetElement = document.getElementById(targetId);
                            }
                            
                            if (targetElement) {
                              console.log('Target element found:', targetElement);
                              console.log('Element position:', targetElement.getBoundingClientRect());
                              console.log('Element offsetTop:', targetElement.offsetTop);
                              
                              // Add a subtle highlight effect
                              targetElement.style.transition = 'box-shadow 0.3s ease-in-out';
                              targetElement.style.boxShadow = '0 0 20px rgba(0, 255, 255, 0.7)'; // Cyan glow effect
                              
                              // Scroll to the element
                              console.log('Scrolling to element...');
                              
                              // Use a more reliable scrolling method
                              const yOffset = -100; // Header offset
                              const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                              
                              window.scrollTo({
                                top: y,
                                behavior: 'smooth'
                              });
                              
                              console.log('Scrolled to position:', y);
                              
                              // Remove the highlight after a few seconds
                              setTimeout(() => {
                                targetElement.style.boxShadow = 'none';
                              }, 2000);
                            } else {
                              console.log('Target element NOT found:', targetId);
                            }
                          } else {
                            console.log('Navigating to:', item.link);
                          }
                        }
                      }}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeatureCards;
