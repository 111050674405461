import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Component that handles scrolling to service sections based on URL hash
 * This component doesn't render anything, it just provides the scrolling functionality
 */
const ScrollToService = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to handle scrolling to the target element
    const scrollToTargetElement = () => {
      if (location.hash) {
        // Remove the # symbol from the hash
        const targetId = location.hash.substring(1);
        
        // Try with the detail- prefix for the detailed view
        const detailTargetId = 'detail-' + targetId;
        
        // Try to find the element with the detail prefix first
        let targetElement = document.getElementById(detailTargetId);
        
        // If not found, try the original ID
        if (!targetElement) {
          targetElement = document.getElementById(targetId);
        }
        
        if (targetElement) {
          // Wait a bit to ensure everything is rendered
          setTimeout(() => {
            // Use a more reliable scrolling method
            const yOffset = -100; // Header offset
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            
            // Scroll to the element with offset for the header
            window.scrollTo({
              top: y,
              behavior: 'smooth'
            });
            
            // Add a cyan glow effect to highlight the target
            targetElement.style.transition = 'box-shadow 0.3s ease-in-out';
            targetElement.style.boxShadow = '0 0 20px rgba(0, 255, 255, 0.7)'; // Cyan glow matching space theme
            
            // Remove the highlight after a few seconds
            setTimeout(() => {
              targetElement.style.boxShadow = '';
            }, 2000);
          }, 100);
          
          return true;
        }
        return false;
      }
      return false;
    };

    // Try to scroll immediately
    if (!scrollToTargetElement()) {
      // If not successful, try again after a delay
      setTimeout(() => {
        if (!scrollToTargetElement()) {
          // Try one more time with a longer delay
          setTimeout(() => {
            scrollToTargetElement();
          }, 1000);
        }
      }, 500);
    }
  }, [location.hash]); // Re-run when the hash changes

  // This component doesn't render anything
  return null;
};

export default ScrollToService;
