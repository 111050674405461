import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
/* import Link from '@mui/material/Link';
import { Facebook, Twitter, GitHub, Instagram, LinkedIn } from '@mui/icons-material'; */

const Footer = () => {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      paddingTop={'1.5em'}
      className="cyber-grid"
      sx={{
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '1px',
          background: 'linear-gradient(90deg, rgba(0,229,255,0) 0%, rgba(0,229,255,0.7) 50%, rgba(0,229,255,0) 100%)',
        }
      }}
    >
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          alignContent={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          {/*           <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="https://facebook.com/"
                target="_blank"
                color="text.primary"
                variant={'subtitle2'}
              >
                <Facebook />
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="https://x.com/"
                target="_blank"
                color="text.primary"
                variant={'subtitle2'}
              >
                <Twitter />
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="https://linkedin.com/"
                target="_blank"
                color="text.primary"
                variant={'subtitle2'}
              >
                <LinkedIn />
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="https://github.com/"
                target="_blank"
                color="text.primary"
                variant={'subtitle2'}
              >
                <GitHub />
              </Link>
            </Box>
            <Box marginTop={1}>
              <Link
                underline="none"
                component="a"
                href="https://instagram.com/"
                target="_blank"
                color="text.primary"
                variant={'subtitle2'}
              >
                <Instagram />
              </Link>
            </Box>
          </Box> */}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="secondary"
          gutterBottom
          className="neon-text"
          sx={{
            fontFamily: '"Roboto Mono", monospace',
            letterSpacing: '1px',
          }}
        >
          &copy; {new Date().getFullYear()} Nebula Networks. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
