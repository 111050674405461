import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';

import Sidebar from '../Sidebar/Sidebar'; // Make sure the Sidebar is correctly imported

// Use correct relative path for the dark logo only
import logoDark from '../../../../assets/nebula_logo_transparent.png';

const Topbar = () => {
  const theme = useTheme();

  // State for managing the sidebar
  const [openSidebar, setOpenSidebar] = useState(false);

  // Functions to handle opening and closing of the sidebar
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Box
      width={'100%'}
      sx={{
        padding: '0.2em 0',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 2px 4px ${alpha(theme.palette.grey[900], 0.2)}`,
      }}
    >
      <Container maxWidth="lg">
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box
            display={'flex'}
            component={RouterLink}
            to="/"
            title="Nebula Networks"
            width={{ xs: 45, md: 70 }}
          >
            {/* Always use dark mode logo */}
            <Box
              component={'img'}
              src={logoDark}
              alt="Nebula Networks Logo"
              height={1}
              width={1}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
            <Box marginLeft={2.5}>
              <Link
                underline="none"
                component={RouterLink}
                to="/"
                color="text.primary"
                sx={{ fontSize: '0.85rem' }}
              >
                Home
              </Link>
            </Box>
            <Box marginLeft={2.5}>
              <Link
                underline="none"
                component={RouterLink}
                to="/services"
                color="text.primary"
                sx={{ fontSize: '0.85rem' }}
              >
                Services
              </Link>
            </Box>
            <Box marginLeft={2.5}>
              <Link
                underline="none"
                component={RouterLink}
                to="/about"
                color="text.primary"
                sx={{ fontSize: '0.85rem' }}
              >
                About
              </Link>
            </Box>
            <Box marginLeft={2.5}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ 
                  py: 0.4, 
                  px: 1.2,
                  fontSize: '0.85rem',
                  background: 'linear-gradient(90deg, #1E88E5 0%, #00BFA5 100%)',
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
                    background: 'linear-gradient(90deg, #1976D2 0%, #00A896 100%)',
                  }
                }}
                onClick={() => {
                  const element = document.getElementById('contact-form');
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
            <Button
              onClick={handleSidebarOpen}  // Open the sidebar
              aria-label="Menu"
              variant={'outlined'}
              sx={{
                borderRadius: 2,
                minWidth: 'auto',
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Sidebar component */}
      <Sidebar
        open={openSidebar}
        onClose={handleSidebarClose}  // Close the sidebar
        variant="temporary"  // Make it temporary for mobile
      />
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
