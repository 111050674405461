import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === 'light' ? light : dark,
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Roboto Mono", monospace',
        h1: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 700,
        },
        h2: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 700,
        },
        h3: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 600,
        },
        h4: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 600,
        },
        h5: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 500,
        },
        h6: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 500,
        },
        subtitle1: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 400,
        },
        subtitle2: {
          fontFamily: '"Exo 2", sans-serif',
          fontWeight: 400,
        },
        body1: {
          fontFamily: '"Roboto Mono", monospace',
        },
        body2: {
          fontFamily: '"Roboto Mono", monospace',
        },
        button: {
          fontFamily: '"Exo 2", sans-serif',
          textTransform: 'none',
          fontWeight: 500,
          letterSpacing: '0.5px',
        },
        caption: {
          fontFamily: '"Roboto Mono", monospace',
          fontSize: '0.75rem',
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              borderRadius: 8,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 24,
              paddingRight: 24,
              marginTop: 8,
              marginBottom: 8,
              marginLeft: 4,
              marginRight: 4,
              boxShadow: mode === 'dark' ? '0 0 15px rgba(0, 229, 255, 0.4)' : 'none',
              '&:hover': {
                boxShadow: mode === 'dark' ? '0 0 20px rgba(0, 229, 255, 0.6)' : 'none',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s ease-in-out',
              },
            },
            containedPrimary: {
              background: mode === 'dark' 
                ? 'linear-gradient(45deg, #1976d2 30%, #2196F3 90%)'
                : 'linear-gradient(45deg, #1565c0 30%, #1E88E5 90%)',
              '&:hover': {
                background: mode === 'dark'
                  ? 'linear-gradient(45deg, #2196F3 30%, #64b5f6 90%)'
                  : 'linear-gradient(45deg, #1E88E5 30%, #42a5f5 90%)',
              },
            },
            containedSecondary: {
              background: mode === 'dark'
                ? 'linear-gradient(45deg, #00b8d4 30%, #00E5FF 90%)'
                : 'linear-gradient(45deg, #00897b 30%, #00BFA5 90%)',
              color: mode === 'dark' ? '#111827' : 'white',
              '&:hover': {
                background: mode === 'dark'
                  ? 'linear-gradient(45deg, #00E5FF 30%, #18ffff 90%)'
                  : 'linear-gradient(45deg, #00BFA5 30%, #1de9b6 90%)',
              },
            },
            outlined: {
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px',
              },
            },
            outlinedPrimary: {
              borderColor: mode === 'dark' ? '#2196F3' : '#1E88E5',
              '&:hover': {
                borderColor: mode === 'dark' ? '#64b5f6' : '#42a5f5',
                backgroundColor: mode === 'dark' ? 'rgba(33, 150, 243, 0.1)' : 'rgba(30, 136, 229, 0.1)',
              },
            },
            outlinedSecondary: {
              borderColor: mode === 'dark' ? '#00E5FF' : '#00BFA5',
              '&:hover': {
                borderColor: mode === 'dark' ? '#18ffff' : '#1de9b6',
                backgroundColor: mode === 'dark' ? 'rgba(0, 229, 255, 0.1)' : 'rgba(0, 191, 165, 0.1)',
              },
            },
            sizeSmall: {
              padding: '8px 16px',
              fontSize: '0.875rem',
            },
            sizeMedium: {
              padding: '12px 24px',
              fontSize: '1rem',
            },
            sizeLarge: {
              padding: '16px 32px',
              fontSize: '1.125rem',
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              fontFamily: '"Roboto Mono", monospace',
              marginTop: 8,
              marginBottom: 8,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: mode === 'dark' ? '#00E5FF' : '#00BFA5',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: mode === 'dark' ? '#00E5FF' : '#00BFA5',
                borderWidth: '2px',
              },
            },
            input: {
              borderRadius: 8,
              padding: '16px',
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              boxShadow: mode === 'dark' 
                ? '0 4px 20px rgba(0, 0, 0, 0.25), 0 0 15px rgba(0, 229, 255, 0.1)'
                : '0 4px 20px rgba(10, 25, 47, 0.1)',
              overflow: 'hidden',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              margin: '16px 0',
              padding: '16px',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: mode === 'dark'
                  ? '0 10px 30px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 229, 255, 0.2)'
                  : '0 10px 30px rgba(10, 25, 47, 0.15)',
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: mode === 'dark' ? '#00E5FF' : '#00BFA5',
              },
            },
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              paddingTop: 24,
              paddingBottom: 24,
              '@media (min-width: 600px)': {
                paddingTop: 32,
                paddingBottom: 32,
              },
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            container: {
              marginTop: 16,
              marginBottom: 16,
            },
            item: {
              paddingTop: 8,
              paddingBottom: 8,
            },
          },
        },
      },
      themeToggler,
    }),
  );

export default getTheme;
