export const light = {
  alternate: {
    main: '#E5E7EB',
    dark: '#d1d5db',
  },
  cardShadow: 'rgba(10, 25, 47, 0.15)',
  mode: 'light',
  primary: {
    main: '#1E88E5',  // Brighter blue
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff',
  },
  secondary: {
    main: '#00BFA5',  // Brighter teal
    light: '#1de9b6',
    dark: '#00897b',
    contrastText: '#fff',
  },
  accent1: {
    main: '#00FFFF',  // Neon Cyan
    light: '#33ffff',
    dark: '#00cccc',
    contrastText: '#111827',
  },
  accent2: {
    main: '#FF00FF',  // Hyper Glow Magenta
    light: '#ff33ff',
    dark: '#cc00cc',
    contrastText: '#fff',
  },
  text: {
    primary: '#111827',  // Space Black
    secondary: '#374151',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#E5E7EB',  // Soft Gray
    default: '#E5E7EB',
    level2: '#d1d5db',
    level1: '#E5E7EB',
  },
};

export const dark = {
  alternate: {
    main: '#1F2937',
    dark: '#111827',
  },
  cardShadow: 'rgba(0, 0, 0, 0.2)',
  common: {
    black: '#000',
    white: '#E5E7EB',
  },
  mode: 'dark',
  primary: {
    main: '#2196F3',  // Brighter blue
    light: '#64b5f6',
    dark: '#1976d2',
    contrastText: '#fff',
  },
  secondary: {
    main: '#00E5FF',  // Brighter teal/cyan
    light: '#18ffff',
    dark: '#00b8d4',
    contrastText: '#111827',
  },
  accent1: {
    main: '#00FFFF',  // Neon Cyan
    light: '#33ffff',
    dark: '#00cccc',
    contrastText: '#111827',
  },
  accent2: {
    main: '#FF00FF',  // Hyper Glow Magenta
    light: '#ff33ff',
    dark: '#cc00cc',
    contrastText: '#fff',
  },
  text: {
    primary: '#E5E7EB',  // Soft Gray
    secondary: '#9CA3AF',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#111827',  // Space Black
    default: '#111827',
    level2: '#1F2937',
    level1: '#111827',
  },
};
