/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Container from 'components/Container';

// Helper function to generate service ID from title - EXACTLY matching services.json
const generateServiceId = (title, prefix = '') => {
  if (!title) return '';
  // Handle special case for SECaaS
  if (title.includes('SECaaS')) return prefix + 'service-secaas';
  // Handle special case for Cloud | IT Modernization
  if (title.includes('Cloud')) return prefix + 'service-cloud-modernization';
  // Handle special case for Penetration Testing
  if (title.includes('Penetration')) return prefix + 'service-pentesting';
  // Handle special case for Continuity of Operations
  if (title.includes('Continuity')) return prefix + 'service-continuity';
  // Handle special case for Cyber Awareness Training
  if (title.includes('Awareness')) return prefix + 'service-cyber-training';
  // Handle special case for Audit Preparation
  if (title.includes('Audit Preparation')) return prefix + 'service-audit-preparation';
  // Handle special case for Vulnerability Scanning
  if (title.includes('Vulnerability')) return prefix + 'service-vulnerability-scanning';
  // Handle special case for Cybersecurity Auditing
  if (title.includes('Cybersecurity Auditing')) return prefix + 'service-cybersecurity-auditing';
  
  // Default case: convert to lowercase, replace spaces with hyphens
  return prefix + 'service-' + title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

const BlogWithLargeImage = ({ services, idPrefix = '' }) => {
  const theme = useTheme();

  return (
    <Box bgcolor={'alternate.main'}>
      <Container bgcolor={'alternate.main'}>
        <Grid container spacing={4}>
          {services.map((item, i) => {
            // Generate the EXACT service ID that matches services.json links
            const serviceId = generateServiceId(item.title, idPrefix);
            
            return (
              <Grid 
                key={i} 
                item 
                xs={12}
                sx={{ marginBottom: 4 }}  // Add margin bottom to create consistent spacing
                id={serviceId} // Use the EXACT ID format as in services.json
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={0}
                  boxShadow={0}
                  display={'flex'}
                  flexDirection={{
                    xs: 'column',
                    md: i % 2 === 0 ? 'row-reverse' : 'row',
                  }}
                  sx={{ 
                    backgroundImage: 'none', 
                    bgcolor: 'transparent',
                    transition: 'box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      boxShadow: '0 0 15px rgba(0, 255, 255, 0.4)' // Cyan glow effect on hover
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: 1, md: '50%' },
                    }}
                  >
                    <Box
                      component={'img'}
                      height={1}
                      width={1}
                      src={item.image}
                      alt={item.title}
                      sx={{
                        objectFit: 'cover',
                        maxHeight: 360,
                        filter:
                          theme.palette.mode === 'dark'
                            ? 'brightness(0.8)'
                            : 'none',
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      paddingX: { xs: 1, sm: 2, md: 4 },
                      paddingY: { xs: 2, sm: 4 },
                      width: { xs: 1, md: '50%' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant={'h6'}
                      fontWeight={700}
                      sx={{ textTransform: 'uppercase' }}
                      className="neon-text" // Add neon text effect for space theme
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary" marginTop={1}>
                      {item.content}
                    </Typography>
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    ></Box>
                  </CardContent>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogWithLargeImage;
