import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from 'components/Container';

const WithSimpleCards = ({ team }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if it's mobile

  return (
    <Box bgcolor={'alternate.main'}
      sx={{
        width: '100%',
        paddingY: 4, // Add vertical padding
      }}
    >
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography fontWeight={700} variant={'h3'} align={'center'}>
              The Team
            </Typography>
          </Box>
          <Grid container spacing={4} direction="column" alignItems="center">
            {team.map((member, i) => (
              <Grid item xs={12} key={i} sx={{ width: isMobile ? '100%' : '80%' }}> {/* 100% width on mobile, 75% on larger screens */}
                <Box
                  component={Card}
                  boxShadow={2}
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row', // Column for mobile, row for larger screens
                    alignItems: 'center',
                    padding: theme.spacing(2), // Add padding inside the card
                    borderRadius: theme.spacing(1), // Optional: Slightly rounded corners
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                      transform: `translateY(-${theme.spacing(1 / 2)})`,
                    },
                  }}
                >
                  <Avatar
                    src={member.avatar}
                    alt={`${member.name} - ${member.title}`} // Descriptive alt text for accessibility
                    sx={{
                      height: 80,
                      width: 80,
                      marginBottom: isMobile ? theme.spacing(2) : 0, // Space between avatar and text for mobile
                      marginRight: isMobile ? 0 : theme.spacing(2), // Space between avatar and text for larger screens
                    }}
                  />
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                    }}
                  >
                    <ListItemText primary={member.name} secondary={member.title} />
                    <Typography variant={'subtitle2'} color={'text.secondary'} marginTop={1}>
                      {member.description}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WithSimpleCards;
