/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer'; // Import the hook
import Container from 'components/Container';

const mock = [
  {
    title: '40',
    subtitle: 'Over 40 years of combined industry experience, providing expert IT strategies to safeguard businesses from evolving threats.',
    suffix: '+',
    start: 5,
  },
  {
    title: '10000',
    subtitle: 'More than 10,000 devices secured, offering proven expertise in protecting complex IT environments.',
    suffix: '+',
    start: 1000,
  },
  {
    title: '100',
    subtitle: '100% of our customers report improved security and peace of mind with our tailored IT and cybersecurity solutions.',
    suffix: '%',
    start: 10,
  },
];


const WithCountUpNumbersAndCoverImage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const { ref, inView } = useInView({
    triggerOnce: true, // Count up only once when in view
    threshold: 0.2,    // Trigger when 20% of the box is visible
  });

  return (
    <Box ref={ref}>
      <Container>
        <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
          <Grid item xs={12} md={6}>
            <Box marginBottom={4}>
              <Typography sx={{ fontWeight: 700 }} variant={'h4'} gutterBottom>
                Your Trusted IT Partner
              </Typography>
              <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
                Empowering businesses through expert IT solutions and leading
                edge cybersecurity, led by industry pioneers.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography variant="h4" color={'primary'} gutterBottom>
                    {inView ? (
                      <CountUp
                        start={0}
                        end={parseFloat(item.title)}
                        duration={2.5}
                        suffix={item.suffix}
                      />
                    ) : (
                      '0' // You can display "0" or placeholder while waiting for the element to come into view
                    )}
                  </Typography>
                  <Typography color="text.secondary" component="p">
                    {item.subtitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={6}
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box component={Card} boxShadow={4} height={1} width={1}>
              <Box
                component={CardMedia}
                height={1}
                width={1}
                minHeight={300}
                image="https://assets.maccarianagency.com/backgrounds/img4.jpg"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WithCountUpNumbersAndCoverImage;
