/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';

const HeroVideo = () => {
  const theme = useTheme();
  return (
    <Box 
      position={'relative'} 
      zIndex={2}
      sx={{
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
      }}
    >
      <Box width={1} height={1} position={'absolute'} overflow={'hidden'}>
        <Box
          width={1}
          height={1}
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          sx={{ transform: 'translate(-50%,-50%)' }}
        >
          <video 
            style={{ 
              width: '100vw',
              height: '100vh',
              objectFit: 'cover',
            }} 
            loop 
            autoPlay 
            muted
          >
            <source src={require('../../assets/nebula1.mp4')} type="video/mp4" />
          </video>
        </Box>
      </Box>
      <Box
        position={'relative'}
        zIndex={2}
        minHeight={'100vh'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          '&::after': {
            content: '""',
            opacity: '0.75',
            width: 1,
            height: 1,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 3,
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 100%)',
          },
        }}
      >
        <Container 
          maxWidth="lg" 
          position={'relative'} 
          zIndex={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Box marginBottom={6}>
              <Typography
                variant={'h3'}
                align={'center'}
                gutterBottom
                sx={{
                  color: theme.palette.common.white,
                  fontWeight: 700,
                  paddingBottom: '2em',
                  lineHeight: 1.4,
                }}
              >
                Nebula Networks
              </Typography>
              <Typography
                variant={'h6'}
                align={'center'}
                sx={{
                  color: theme.palette.common.white,
                  fontWeight: 700,
                  paddingBottom: '1.5em',
                  lineHeight: 1.6,
                  maxWidth: '800px',
                  margin: '0 auto',
                }}
              >
                We make the world safer and more connected with expert Managed
                IT Services, comprehensive Cybersecurity Solutions, and
                cutting-edge IT infrastructure management—all delivered at
                unmatched value for your business.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 4,
              }}
            >
              <Button
                sx={{ 
                  height: 54, 
                  whiteSpace: 'nowrap', 
                  minWidth: 180,
                  fontSize: '1.1rem',
                  py: 2,
                  px: 4,
                  background: 'linear-gradient(90deg, #1E88E5 0%, #00BFA5 100%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #1976D2 0%, #00A896 100%)',
                    boxShadow: '0 0 15px rgba(0, 255, 255, 0.7)',
                  }
                }}
                onClick={() => {
                  const element = document.getElementById('contact-form');
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Get Started
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HeroVideo;
